import { Backdrop } from "@mui/material";
import React, { useEffect, useState } from "react";

import Banner from "../components/Banner";
import Products from "../components/Products";
import { getParamUrl } from "../extra/utils";
import { useStyles } from "../styles/homeStyles";
import BHSucKhoe from "./BHSucKhoe";

import comingSoon from "../assets/icons/coming-soon.svg";
import iconX from "../assets/images/icon-x.png";
import popup from "../assets/images/popup-1.png";

function Home({ isMobile, dispatch, state }) {
  const platform = getParamUrl("platform");
  const partner = getParamUrl("partner_id");

  const [openPopup, setOpenPopup] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        const benefitElement = document.getElementById("benefit");
        if (benefitElement) {
          const elementPosition = benefitElement.getBoundingClientRect().top;
          if (elementPosition < 350) {
            dispatch({ type: "onChangeScroll", value: true });
          } else {
            dispatch({ type: "onChangeScroll", value: false });
          }
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    const targetDate = new Date("12/08/2024"); //endDate
    const currentDate = new Date();
    if (currentDate > targetDate) {
      setOpenPopup(false);
    } else {
      setOpenPopup(true);
    }
  }, []);

  const renderProductPage = () => {
    const currentProduct = getParamUrl("code");
    switch (currentProduct) {
      case "health":
        return (
          <BHSucKhoe
            isMobile={isMobile}
            productSelected={state.productSelected}
          />
        );
      default:
        return (
          <div style={{ textAlign: "center", margin: "15% 0" }}>
            <img src={comingSoon} alt="coming-soon" className="coming-logo" />
          </div>
        );
    }
  };

  return (
    <div className={classes.container}>
      {platform !== "app" && !partner && (
        <>
          <Banner isMobile={isMobile} banners={state.banners} />
          <Products isMobile={isMobile} state={state} dispatch={dispatch} />
        </>
      )}
      {renderProductPage()}
      {/* popup img */}
      {openPopup && (
        <Backdrop className={classes.popUp} open={openPopup}>
          <div className="wrapper">
            <img
              src={iconX}
              alt="iconX"
              className="icon-close"
              onClick={() => setOpenPopup(false)}
            />
            <img src={popup} alt="popup" className="popup-img" />
          </div>
        </Backdrop>
      )}
    </div>
  );
}

export default Home;
