import { Button, Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import warningIcon from "../../assets/icons/warning-noti.svg";

function MdChangedFees({ data, dispatch, scrollTo }) {
  const classes = useStyles();
  return (
    <Dialog open={data?.open} maxWidth="xs">
      <div className={classes.modal}>
        <img src={warningIcon} alt="noti-icon" />
        <div className="title">
          Người được bảo hiểm{" "}
          <span className="color-txt">{data?.name || "nguyen van a"}</span> đã
          cập nhật lại phí
        </div>
        <div>
          Lý do: Thông tin ngày sinh, giới tính thay đổi so với gói ban đầu đã
          chọn
        </div>
        <div>Quý khách muốn cập nhật lại quyền lợi và phí bảo hiểm không?</div>
        <div className="action">
          <Button
            className="btn back"
            onClick={() => {
              scrollTo();

              dispatch("closeMdChangedFees");
              scrollTo();
            }}
          >
            Giữ nguyên
          </Button>
          <Button
            className="btn next"
            onClick={() => {
              dispatch({
                type: "changeTabStep1",
                index: data?.index,
                value: 1,
              });
              dispatch("closeMdChangedFees");
              scrollTo();
            }}
          >
            Cập nhật lại
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default MdChangedFees;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",

    gap: 15,
    padding: 32,
    lineHeight: 1.4,

    "& .title": {
      fontWeight: 600,
    },
    "& .color-txt": {
      color: "#FB7800",
      textTransform: "capitalize",
    },
    "& .action": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 15,
      width: "100%",
    },
    "& .btn": {
      width: "48%",
      padding: "6px 15px",
      borderRadius: 6,
      textTransform: "none",
      fontSize: 14,
      fontWeight: 500,
    },

    "& .back": {
      border: "1px solid #F0F2F5",
      background: "#F0F2F5",
    },

    "& .next": {
      color: "#fff",
      background: "#0B7CE4",
      border: "1px solid #0B7CE4",
      "&:hover": {
        color: "#fff",
        background: "#0B7CE4",
      },
    },
  },
}));
