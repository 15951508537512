import axios from "axios";
import { Base64 } from "js-base64";
import { getParamUrl } from "../views/extra/utils";

const API_URL = process.env.REACT_APP_CORE_SERVER;
const API_UPLOAD = process.env.REACT_APP_G2DOMAIN;

const Header = !getParamUrl("token")
  ? {}
  : {
      headers: {
        Authorization: `bearer ${getParamUrl("token")}`,
      },
    };

async function apiCall(method, url, data) {
  const data_base64 = Base64.encode(JSON.stringify(data));
  try {
    const res = await axios({
      method: method,
      url: API_URL + url,
      data: {
        data: data_base64,
      },
      // data,
      ...Header,
    });
    return res?.data;
  } catch (err) {
    return err?.response?.data;
  }
}

async function apiUpload(method, url, data) {
  try {
    const res = await axios({
      method: method,
      url: API_UPLOAD + url,
      data: data,
      headers: Header,
    });
    return res.data;
  } catch (err) {
    return err?.response?.data?.message || "Có lỗi xảy ra";
  }
}

async function apiGetFileByUrl(url) {
  try {
    const res = await axios({
      method: "GET",
      url: url,
      responseType: "blob",
    });
    return res.data;
  } catch (err) {
    return err?.response?.data?.message || "Có lỗi xảy ra";
  }
}

async function apiCallPartner(method, url, data) {
  const data_base64 = Base64.encode(JSON.stringify(data));
  try {
    const res = await axios({
      method: method,
      url: API_URL + url,
      data: {
        data: data_base64,
      },
      headers: {
        Authorization: `bearer ${getParamUrl("partner_id")}`,
      },
    });
    return res?.data;
  } catch (err) {
    return err?.response?.data;
  }
}

const api = { apiCall, apiUpload, apiGetFileByUrl, apiCallPartner };
export default api;
